<!--
Generic table with actions on top
-->

<template>
  <v-card :width="width">
    <v-card-text v-if="cardTitle" class="text-center">
      <h4>{{ cardTitle }}</h4>
    </v-card-text>
    <v-divider v-if="cardTitle" />
    <v-card-actions v-if="showActions" style="height: 40px" class="pa-4">
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="captionPathPrefix" icon color="primary" v-on="on" @click="captions = true"
            ><v-icon> help </v-icon>
          </v-btn>
        </template>
        <span>{{ $t("basic_dialogs.glossary") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <download-excel :fetch="getData" :name="fullExportFilename">
            <v-btn icon color="primary" :disabled="loading" v-on="on"><v-icon> save </v-icon> </v-btn>
          </download-excel>
        </template>
        <span>{{ $t("basic_dialogs.export") }}</span>
      </v-tooltip>
    </v-card-actions>
    <v-divider v-if="showActions" />

    <v-data-table :headers="headers" :items="items" v-bind="{ ...fullTableProps, ...$attrs }" :loading="loading">
      <template v-slot:[`header.${header.value}`]="scope" v-for="header in headers">
        {{ getHeaderI18n(header) }}
      </template>
      <template v-slot:[`item.${header.value}`]="scope" v-for="header in headers">
        <slot :name="`item.${header.value}`" v-bind="scope">
          {{ itemFormatter(header, scope.item) }}
        </slot>
      </template>
      <template v-if="'body' in $slots" v-slot:body="scope">
        <slot name="body" v-bind="scope"></slot>
      </template>
    </v-data-table>
    <kite-bottom-caption
      v-if="captionPathPrefix"
      v-model="captions"
      :items="headers"
      :text="header => getHeaderI18n(header)"
      :caption="header => $t(`${captionPathPrefix}.${header.text}`)"
    />
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { formatTableContent } from "@/capucine_utils";
import { formatTableDataWithHeaders } from "@/functions-tools";

function defaultItemFormatter(header, item) {
  let value;
  if ("format" in header) {
    value = formatTableContent(item[header.value], header.format);
  } else {
    value = item[header.value];
  }
  return value;
}

export default Vue.component("kite-table", {
  inheritAttrs: false,
  components: {},
  props: {
    // table headers { text, value, format? }
    headers: {
      type: Array,
      required: true
    },
    // table items (see vuetify data-table)
    items: Array,
    // card title
    cardTitle: String,
    // card width
    width: String,
    // table contents loading
    loading: Boolean,
    // additional props passed to the table
    tableProps: Object,
    // show actions in section on top of the card
    showActions: {
      type: Boolean,
      default: true
    },
    // filename given to the XLS export
    exportFilename: {
      type: String,
      default: "data_table.xls"
    },
    // i18n prefix for headers, added before header.text
    headerPathPrefix: String,
    // i18n prefix for catpions added before header.text
    captionPathPrefix: String,
    // function(header, item) used to format items display
    itemFormatter: {
      type: Function,
      default: defaultItemFormatter
    }
  },

  data() {
    return {
      captions: false
    };
  },

  computed: {
    fullTableProps() {
      return {
        "loading-text": this.$t("basic_dialogs.loading"),
        "no-data-text": this.$t("basic_dialogs.no_data"),
        ...this.tableProps
      };
    },
    fullExportFilename() {
      let export_filename = this.exportFilename;
      if (!export_filename.endsWith(".xls")) {
        export_filename = export_filename + ".xls";
      }
      return export_filename;
    }
  },

  methods: {
    getData() {
      return formatTableDataWithHeaders(this.headers, this.items, this.valueFormat, this.getI18n);
    },
    getHeaderI18n(header) {
      let prefix = "";
      if (this.headerPathPrefix) {
        prefix = this.headerPathPrefix + ".";
      }
      let path = prefix + (header.text || header.value);
      return this.$t(path);
    }
  }
});
</script>
