<template>
  <v-data-table
    dense
    :items="buildStepperRecapAsRecords(parameters)"
    disable-sort
    :headers="headers"
    hide-default-footer
    hide-default-header
  >
    <template v-slot:item.text="{ item }">
      <div style="font-weight: bold">{{ item.text }}</div>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";
import i18n from "@/plugins/lang";
import { USE_CASES } from "@/simulation";

export default Vue.component("stepper-recap-table", {
  components: {},
  props: ["service", "useCase", "scenarioName", "parameters"],
  data: () => ({
    headers: [
      { text: i18n.t("simulations.stepper.headers.text"), value: "text", width: "40%" },
      { text: i18n.t("simulations.stepper.headers.value"), value: "value", width: "60%" }
    ]
  }),

  computed: {
    ...mapState("simulations", ["valid_step"]),
    ...mapState(["async"])
  },

  methods: {
    buildStepperRecapAsRecords(parameters) {
      let use_case_recap = USE_CASES[this.useCase]?.recap || [];
      let records = this.scenarioName
        ? [{ text: i18n.t("simulations.stepper.labels.scenario_name"), value: this.scenarioName }]
        : [];

      let record;

      // browse use case recap items
      for (let i = 0; i < use_case_recap.length; i++) {
        let item = use_case_recap[i];

        record = this.getRecord(item, parameters);

        // add item to records
        records.push(record);
      }

      return records;
    },
    getRecord(recapItem, parameters) {
      let record = {
        value: undefined,
        text: undefined
      };
      let value_key;
      let text_key;

      // if object, look for value and text keys
      if (typeof recapItem == "object") {
        value_key = recapItem.value;
        text_key = recapItem.text;
        // otherwise use the item as a key for both value and text
      } else {
        value_key = recapItem;
        text_key = recapItem;
      }

      // otherwise fetch the correct text in the relevant labels
      if (text_key.startsWith("generic.")) {
        record.text = i18n.t("simulations.stepper.labels." + text_key.substring(8));
      } else {
        record.text = i18n.t("simulations.use_cases." + this.service + "." + this.useCase + ".labels." + text_key);
      }

      // fetch the correct value in the stepper parameters
      if (typeof value_key == "function") {
        record.value = value_key(parameters);
      } else {
        if (value_key.startsWith("generic.")) {
          value_key = value_key.substring(8);
        }
        record.value = value_key
          .replace(/\[|\]\.?/g, ".")
          .split(".")
          .filter(s => s)
          .reduce((acc, val) => acc && acc[val], parameters);
      }
      return record;
    }
  }
});
</script>
