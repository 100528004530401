export default {
  welcome: {
    button: "Entrer",
    settings: "Paramètres",
    update: "Dernière mise à jour",
    guest: "Invité",
    connect: "Connexion",
    disconnect: "Déconnexion",
    welcome: "Bienvenue",
    authenticating: "Authentification",
    isAuthenticated: "Redirection vers l'authentification",
    mapboxError: "Aucune clé Mapbox n'est définie. Merci d'en ajouter une dans le fichier .env.",
    webglError: "WebGL n'est pas activé. Veuillez configurer votre navigateur.",
    use_intro: "Pour démarrer l'utilisation de Kite, cliquez sur un onglet en haut de cette page.",
    help: {
      button: "Guides pas à pas",
      tooltip: "Accéder aux guides pas à pas et à la documentation"
    }
  },
  upload: {
    success: "Votre envoi s'est déroule avec succes",
    failed: "Erreur d'envoi"
  },
  visualisation: {
    loading: "Chargement"
  },
  basic_dialogs: {
    cancel: "Annuler",
    clear: "Effacer",
    close: "Fermer",
    reset: "Réinitialiser",
    validate: "Valider",
    validate_and_save: "Valider et sauvegarder",
    start: "Commencer",
    done: "Terminer",
    add: "Ajouter",
    open: "Ouvrir",
    execute: "Exécuter",
    copyof: "Copie de ",
    delete: "Supprimer",
    copy: "Dupliquer",
    download: "Télécharger les données",
    draw: "Dessiner",
    import: "Importer",
    export: "Exporter",
    loading: "Chargement",
    remove: "Retirer",
    modify: "Modifier l'affichage du calque",
    save: "Sauvegarder dans le projet",
    load: "Charger",
    confirm_delete: "Voulez vous vraiment supprimer cet objet ?",
    share: "Partager",
    undefined: "Non défini",
    filename: "Nom du fichier",
    format: "Format",
    confirm_delete_map: "Voulez vous vraiment supprimer cette carte ?",
    no_data: "Pas de données",
    zoom: "Zoom",
    glossary: "Glossaire",
    selected: "{nb} sélectionnés",
    rename: "Renommer"
  },
  basic_transport: {
    direction: "Direction",
    directions: {
      departure: "Départ",
      arrival: "Arrivée",
      0: "Aller",
      1: "Retour"
    },
    mode: "Mode",
    speed: "Vitesse",
    modes: {
      car: "Voiture",
      pedestrian: "Piéton",
      bike: "Vélo",
      electrical_bike: "Vélo éléctrique",
      public_transport: "Transport public"
    },
    route_types: {
      0: "Tramway",
      1: "Métro",
      2: "Train",
      3: "Bus",
      4: "Ferry",
      5: "Tramway à câble",
      6: "Téléphérique suspendu",
      7: "Funiculaire",
      11: "Trolleybus",
      12: "Monorail"
    },
    day_type: "Type de jour",
    day_types: {
      complete_day_type: "{weekday} - {period} ({date})",
      LMaJV: "LuMaJeVe",
      Me: "Mercredi",
      Sam: "Samedi",
      DimF: "Dimanche Jours fériés",
      HV: "Hors vacances",
      VAC: "Vacances",
      ETE: "Été"
    }
  },
  basic_headers: {
    name: "Nom",
    type: "Type",
    date: "Date",
    actions: "Actions",
    tools: "Outils",
    displayed: "Affiché",
    source: "Source",
    theme: "Thème",
    creator: "Auteur",
    label: "Label",
    value: "Valeur"
  },
  units: {
    sec: "secondse",
    min: "minutes",
    hours: "heures",
    m: "mètres",
    km: "kilomètres",
    km_per_hour: "km/h"
  },
  schema: {
    download: "Télécharger le schema",
    type: "doit être du type",
    required: "Propriété requise manquante",
    constraint: "doit être"
  },
  boolean: {
    true: "Oui",
    false: "Non"
  },
  file_input_dialog: {
    title: "Importation de fichiers",
    introduction:
      "<br/>Vous pouvez importer deux types de fichiers:\
      <ul>\
        <li>Flux OD: au format CSV ou XLSX</li>\
        <li>OD d'individus: au format GeoJSON</li>\
      </ul>\
      <br/>\
      Pour débuter, vous pouvez utiliser l'un de ces deux exemples\
      (à télécharger sur votre ordinateur, puis à importer ici):\
      <ul>\
        <li><a href='static/data/example_od_flows.xlsx' download='example_od_flows.xlsx'>Exemple de flux OD</a></li>\
        <li><a href='static/data/example_od_user.geojson' download='example_od_user.geojson'>\
          Exemple OD d'individus\
        </a></li>\
      </ul>",
    placeholder: "Choisissez le(s) fichier(s) à importer"
  },
  about: {
    button: "À propos",
    description:
      'Kite (prononcer [kït]) fait référence au Milan, un rapace à la vue perçante qui est aisément observable dans les Pyrénées. Kite a initialement été développé pour visualiser les données du simulateur multi-agents <a href="https://github.com/tellae/starling" target="_blank">Starling</a>. Que ce soit les données d\'entrées (population synthétique des déplacements), et les données de sortie (traces dynamiques et KPI). Kite a ensuite évolué pour fournir un environnement plus complet de visualisation et d\'analyse des données de mobilité et des systèmes de transport.'
  },
  kite_layers: {
    types: {
      Undefined: "Non défini",
      Multiple: "Multiple",
      Circle: "Cercle",
      Line: "Ligne",
      OffsetLine: "Ligne décalée",
      Symbol: "Symbole",
      Fill: "Remplissage",
      Heatmap: "Heatmap",
      Geojson: "GeoJSON",
      Scatterplot: "Nuage de points",
      Arc: "Arc",
      FlowMap: "FlowMap"
    },
    layers: {
      "drawn-pt-line": {
        name: "Dessin de ligne"
      },
      "drawn-pt-line-buffer": {
        name: "Tampon du dessin de ligne"
      },
      "main-lines-name": {
        name: "Nom des lignes principales"
      },
      "isochrone-public-transport": {
        name: "Isochrone transport public"
      },
      "gtfs-stops": {
        name: "Points d'arrêt transport public"
      },
      "gtfs-lines": {
        name: "Lignes de transport public"
      },
      "users-heatmap": {
        name: "Heatmap OD"
      },
      "origin-scatterplot": {
        name: "Origines OD"
      },
      "destination-scatterplot": {
        name: "Destinations OD"
      },
      "users-flowmap": {
        name: "Flux OD"
      },
      "users-arclayer": {
        name: "Arcs OD",
        origin: "Origine",
        destination: "Destination"
      },
      "users-arclayer-simulation": {
        name: "Arcs OD simulation"
      },
      graph: {
        name: "Graphe de simulation"
      },
      area: {
        name: "Zone de service"
      },
      staticpoint: {
        name: "Agents statiques"
      },
      stoppedmovingpoint: {
        name: "Agents arrêtés"
      },
      movingpoint: {
        name: "Agents en mouvement"
      }
    }
  },
  map_actions: {
    running: "Terminez l'action en cours avant de démarrer une nouvelle",
    search: {
      search_location: "Chercher un lieu"
    },
    draw: {
      title: "Dessin d'un calque",
      desc: "Dessinez des formes pour ajouter un nouveau calque.\
        <ol>\
        <li>Activez le bouton de forme pour créer une forme</li>\
        <li>Cliquez sur la carte pour dessiner la forme</li>\
        <li>Double-cliquez pour terminer le dessin</li>\
        </ol>",
      polygon: "Polygone",
      linestring: "Ligne",
      points: "Points"
    },
    zone_selection: {
      title: "Sélection d'entités spatiales",
      desc: "Choisissez une zone à étudier.\
      <ol>\
      <li>Sélectionnez une échelle administrative</li>\
      <li>Cliquez sur la carte pour sélectionner une ou plusieurs entités</li>\
      </ol>"
    },
    point_selection: {
      title: "Définition d'un point d'intérêt",
      map_select: "Géolocalisation",
      zoom: "Zoomer sur le point",
      click: "Clic sur la carte",
      address_select: "Définir à partir d'une adresse",
      address: "Adresse",
      lng: "Longitude",
      lat: "Latitude"
    },
    map_export: {
      menu_tooltip: "Exporter la carte en PNG ou PDF",
      formats: {
        pdf_report: "Carte avec légende au format PDF",
        png_report: "Carte avec légende au format PNG",
        png_image: "Carte seule au format PNG"
      },
      title: "Export de la carte",
      layer_select: {
        label: "Calques exportés dans la légende",
        no_data: "Aucun calque ne peut être ajouté à la légende",
        placeholder: "Aucun calque sélectionné",
        all: "Tous",
        layers_selected: "{n} calques sélectionnés"
      },
      sources_select: {
        label: "Sources",
        hint: "Choisissez parmi les sources connues ou ajoutez-en de nouvelles",
        placeholder: "Aucune source sélectionnée",
        search:
          "Aucune source connue ne correspond à '{search}'. Appuyez sur <kbd>Entrée</kbd> pour en créer une nouvelle"
      },
      map_title: "Titre de la carte",
      default_map_title: "Ma carte"
    }
  },
  map_export: {
    texts: {
      legend_title: "Légende",
      sources: "Sources",
      other: "Autre"
    },
    success_message: "Export de la carte en cours de création"
  },
  add_dialog: {
    tabs: {
      database: "Base de données",
      file: "Fichier",
      url: "Url",
      draw: "Dessin",
      project: "Projet"
    },
    titles: {
      stats: "Statistiques",
      name: "Nom de la donnée ajoutée",
      config: "Configuration de l'importation"
    },
    basics: {
      no_data: "Donnée manquante",
      file_input_placeholder: "Sélectionnez un fichier",
      multiple_file_input_placeholder: "Sélectionnez un ou plusieurs fichiers"
    },
    errors: {
      name_required: "Nom manquant",
      name_length_error: "Le nombre de caractères doit être inférieur à {max_size}",
      name_regex: "Caractères interdits",
      add: "An error happened while adding data"
    }
  },
  map_layers: {
    name: "Calques",
    desc: "Cartographiez les données territoriales sur votre carte",
    layer_add: "Calques",
    kite_layers: "Liste des calques",
    base_layer: "Fond de plan",
    opacity: "Opacité",
    maps: "Cartes",
    naming: "Nommez votre layer",
    default_name: "Mon calque",
    tooltips: {
      map_views: "Sauvegarder ou charger des cartes",
      layer_add: "Ajouter des calques de données"
    },
    layer_table: {
      headers: {
        drag: "",
        name: "Nom",
        type: "Type",
        color: "Couleur",
        size: "Taille",
        display: "Affiché",
        actions: "Actions",
        theme: "Thème",
        dataSource: "Source"
      },
      no_data: "Veuillez ajouter un calque de données",
      display_builtin: "Afficher tous les calques",
      hide_builtin: "Masquer les calques fonctionnels",
      errors: {
        loading: "Erreur au chargement de la liste des calques: veuillez relancer l'application",
        download: "Erreur lors du téléchargement du calque: {error}",
        minzoom: "La carte n'est pas assez zoomée, impossible de télécharger le calque"
      }
    },
    add_page: {
      stats: {
        nb_features: "Nombre d'éléments : ",
        geometry: "Géométrie du calque : "
      },
      name_prefix: "Mon calque ",
      type: "Type de calque",
      add_error: "Une erreur est survenue lors de l'ajout du calque",
      validation_error: "La donnée n'est pas un GeoJSON valide"
    },
    layer_from_file: {
      title: "Importation d'un calque à partir d'un fichier",
      description: "Ajoutez un fichier GeoJSON",
      multiple_file_error: "Seul un fichier à la fois peut être ajouté",
      json_error: "Formatage JSON incorrect",
      line_info_error: "LineString ou MultiLineString geometries autorisées"
    },
    database_layers: {
      title: "Importation de calques à partir des bases de données",
      description: "Ajoutez des calques depuis les bases de données",
      theme_filter: "Thème",
      refresh: "Mettre à jour avec l'emprise de la carte",
      refresh_error: "Une erreur est survenue lors de l'accès à la base de données des calques",
      viewport_limited_tooltip: "La donnée sera chargée sur l'emprise de la carte",
      viewport_limit_error: "L'emprise de la carte est trop grande pour charger ce calque",
      empty_viewport_limited_layer:
        "Le calque importé est vide. Vérifiez que l'emprise de la carte est suffisante pour inclure tous les objets voulus.",
      themes: {
        empty: "Aucun thème sélectionné",
        all: "Tous les calques",
        carpooling: "Covoiturage",
        demography: "Démographie",
        employment: "Emploi",
        rail: "Ferroviaire",
        schooling: "Formation",
        travel_generators: "Générateurs de déplacements",
        mobility: "Mobilités",
        land_use: "Occupation du sol",
        income: "Revenus et niveau de vie",
        public_transport: "Transports publics",
        zoning: "Zonages et périmètres",
        car_sharing: "Autopartage",
        bike: "Vélo",
        car: "Voiture",
        walk: "Piéton"
      }
    },
    layer_from_url: {
      title: "Importation d'un calque à partir d'une URL",
      intro: "Ajoutez une URL qui pointe vers un GeoJSON",
      example0: "Exemple : choisir un jeu de données sur",
      example1: " et créer une URL d'export depuis l'"
    },
    geometries: {
      LineString: "Ligne",
      Polygon: "Polygone",
      Point: "Point",
      multiple: "Multiple",
      empty: "Donnée vide",
      no_data: ""
    }
  },
  map_view: {
    manager: {
      title: "Gestion des cartes",
      tooltip: "Les cartes sont localisées et contiennent différents objets à afficher",
      save_map: "Sauvegarder la carte actuelle",
      save_zoom: "Sauvergarder le zoom actuel",
      tabs: {
        map: "Cartes",
        zoom: "Zooms"
      }
    },
    add: {
      title: "",
      tooltip:
        "Vérifiez les éléments contenus dans la carte. Les données les décrivant seront sauvegardées dans le projet.",
      name_new: {
        map: "Nouvelle carte",
        zoom: "Nouveau zoom"
      }
    },
    share: {
      copied: "Adresse copiée dans le presse-papier"
    },
    item_types: {
      layer: "Calque",
      custom_layer: "Calque personnalisé",
      database_layer: "Calque de base de données",
      flows: "Flux",
      gtfs_network: "Réseau GTFS"
    },
    errors: {
      item_error: {
        load: "Une erreur est survenue lors du chargement de '{name}' ({type})",
        creation: "Une erreur est survenue lors de l'ajout de '{name}' ({type})"
      },
      create_error: "Erreur lors de la création de la carte",
      access_error:
        "Vous n'avez pas accès à la sauvegarde des données de type {type}. Ces données n'ont pas été ajoutées à la carte. Contactez nous par email à {mail} pour plus d'informations\
      et bénéficier d'un accès. ",
      share_error: "Erreur lors de la création du lien de partage de la carte",
      renaming_error: "Erreur pour renommer la carte",
      load_error: "Erreur de chargement de la carte",
      delete_error: "Erreur pour supprimer la carte"
    },
    sources: {
      user: "Données utilisateur"
    }
  },
  od: {
    flowmap: {
      in: "Entrants",
      out: "Sortants",
      internal: "Internes",
      click: "Cliquer pour afficher les flux",
      trips: "Nombre"
    },
    error: {
      extra: "Trop de fichiers sélectionnés",
      extension: "Extension non compatible avec le nombre de fichiers",
      name: "Les fichiers de flux OD doivent être nommés 'flows' et 'locations'",
      extra_worksheet: "Trop de feuilles dans le fichier excel",
      worksheet_name: "Une feuille doit commencer par",
      import: "Veuillez vérifier le contenu du fichier",
      nested: "Il faut un fichier csv et un 'nested' json",
      agent_id: "agent_id est une propriété obligatoire",
      agent_type: "agent_type est une propriété obligatoire",
      unknown_agent_type: "Type d'agent inconnu"
    }
  },
  flows: {
    name: "Flux",
    flows: "Liste des flux",
    desc: "Visualisez et analysez les flux OD (Origine - Destination)",
    type: {
      FLOWMAP: "Flux",
      STARLING: "OD"
    },
    manager: {
      add: {
        button: "Flux",
        tooltip: "Ajouter des données de flux",
        stats: {},
        config: {
          type: "Type de flux"
        },
        file: {
          title: "Importation des flux à partir d'un fichier",
          intro:
            "<br/>Vous pouvez importer deux types de fichiers:\
            <ul>\
              <li>Flux OD: au format CSV ou XLSX</li>\
              <li>OD d'individus: au format GeoJSON</li>\
            </ul>\
            <br/>\
            Vous pouvez utiliser l'un de ces deux exemples\
            (à télécharger sur votre ordinateur, puis à importer ici):\
            <ul>\
              <li><a href='static/data/example_od_flows.xlsx' download='example_od_flows.xlsx'>Exemple de flux OD</a></li>\
              <li><a href='static/data/example_od_user.geojson' download='example_od_user.geojson'>\
                Exemple OD d'individus\
              </a></li>\
            </ul>"
        },
        project: {
          title: "Importation des flux à partir du projet",
          placeholder: "Sélectionnez une donnée de flux"
        }
      },
      table: {
        no_data_text: "Aucun flux à afficher",
        edit_name: "Modifier le nom des flux",
        edit_taken: "Ce nom existe déjà",
        edit_confirm: "Appuyer sur Entrée pour confirmer",
        headers: {
          name: "Nom",
          type: "Type",
          displayed: "",
          actions: "Actions",
          tools: "Outils"
        },
        tooltip: {
          copy: "Faire une copie",
          download: "Télécharger les données",
          delete: "Supprimer",
          aggregate: "Agréger les flux",
          save: "Sauvegarder",
          summary: "Tableau récapitulatif des flux",
          regroup: "Regrouper les OD"
        }
      }
    },
    filters: {
      title: "Filtres",
      selected_location: "Noeud sélectionné : ",
      flowmap_minimum: "Flux minimum",
      flowmap_minimum_scale: "Facteur d'échelle",
      starling_proportion: "Proportion de l'échantillon aléatoire (en %)",
      seed: "Graine pour l'échantillon aléatoire"
    },
    plots: {
      title: "Graphiques",
      category: "Propriété",
      interval: "Intervalle",
      to_numbers: "Afficher les valeurs",
      to_percent: "Afficher les pourcentages"
    },
    statistics: {
      title: "Statistiques"
    },
    database: {
      title: "Importation depuis les bases de données",
      source: {
        text: "Sélection des données",
        data: "Données",
        zoning: "Zonage",
        date: "Date",
        source: "Source"
      },
      attributes: {
        text: "Sélection des attributs",
        label: "Attributs"
      },
      spatial: {
        text: "Sélection spatiale",
        explanation: {
          action: "Cliquez sur",
          comment: "pour sélectionner les entités spatiales"
        },
        zoning: "Échelle administrative",
        placeholder: "Aucune entité sélectionnée",
        join: "Type de sélection des flux",
        operations: {
          intersect: "Intersection",
          intern: "Interne",
          incoming: "Entrant",
          outgoing: "Sortant",
          extern: "Externe",
          incoming_outgoing: "Entrant + Sortant"
        }
      },
      count: {
        text: "Autres filtres",
        label: "Flux minimum"
      }
    },
    aggregation: {
      title: "Agrégation des flux",
      information:
        "Agrégation des déplacements individuels, sur une échelle administrative\
        ou automatiquement en regroupant les points proches.",
      selection: "Sélection de la méthode d'agrégation",
      admin: {
        title: "Échelle administrative",
        text: "Sélection de l'échelle administrative d'agrégation",
        name_format: "{original_name} agrégés au zonage {zoning}"
      },
      dbscan: {
        title: "Clustering",
        text: "Choix de la distance maximale entre deux points et du nombre minimal de points\
          pour former un groupe (cluster)\
          Attention : les points sans groupe seront retirés des flux.\
          Il est recommandé de garder le nombre minimal de points par groupe à 1.",
        distance: "Distance maximale entre deux points",
        min_samples: "Nombre minimum de points par groupe",
        rules: {
          min_samples: {
            empty: "Le nombre minimum de points par groupe est obligatoire.",
            positive: "Le nombre minimum de points par groupe doit être positif."
          },
          distance: {
            empty: "La distance maximale entre deux points est obligatoire.",
            positive: "La distance maximale entre deux points doit être positive."
          }
        },
        name_format: "{original_name} regroupés avec l'algorithme DBSCAN"
      },
      attributes: {
        title: "Attributs",
        text: "Sélection des attributs liés à l'origine et à la destination.\
          L'algorithme regroupe les flux sur ces attributs.",
        origin: "Attribut de l'origine",
        destination: "Attribut de la destination",
        name_format: "{original_name} regroupés par attributs"
      },
      "custom-zoning": {
        title: "Personnalisée",
        text: "Agrégation des flux à l'aide de votre zonage personnalisé.\
          <br/>\
          <ol>\
            <li>Préparer un zonage de polygones contenant les champs <i>id</i> et <i>name</i></li>\
            <li>Importer ici ce zonage au format GeoJSON</li>\
          </ol>\
          <a href='static/data/example_zonage.geojson' download='example_zonage.geojson'>Exemple de zonage</a>\
          <br/>",
        file_input: "Fichier GeoJSON de zonage",
        name_format: "{original_name} agrégés avec un zonage personnalisé"
      },
      centered: {
        title: "Automatique (beta)",
        information: "Regroupement des flux par échelle administrative autour de l'entité sélectionnée",
        text1: "Sélection de l'échelle administrative de base des flux",
        recommendation: "Recommandation: prendre l'échelle administrative de vos données",
        text2: "Sélection de l'entité centrale de regroupement des flux",
        zoning: "Échelle administrative",
        location: "Entité"
      },
      select_attributes: "Sélection des attributs à conserver",
      attributes_label: "Attributs"
    },
    summary: {
      title: "Tableau récapitulatif des flux",
      headers: {
        origin: "Origine",
        dest: "Destination",
        count: "Nombre",
        percentage: "Pourcentage"
      }
    },
    zonings: {
      ind: "Individuel",
      iris: "IRIS",
      com: "Commune",
      epci: "EPCI",
      dep: "Département",
      reg: "Région"
    }
  },
  trace: {
    name: "Trace",
    file: "Import traces Starling",
    analysis: "Analyses",
    iconrules: "Regles d'icones",
    error: {
      no_file: "Aucun fichier trouvé. Une erreur a été rencontrée à la lecture du fichier.",
      multiple_file: "Un seul fichier autorisé.",
      format: "Les seuls formats acceptés sont geojson et geojson.gz."
    },
    analysis_dialog: {
      title: "Analyses traces",
      intro: "Ici sont affichés des indicateurs sur la trace chargée en mémoire.",
      headers: {
        agent_type: "Type d'agent",
        number: "Nombre"
      },
      no_data: "La trace ne peut être decomposée par type d'agent"
    },
    paths: {
      button: "Trajets",
      title: "Trajets des agents",
      agent_type: "Type d'agent",
      agent_id: "Identifiant de l'agent",
      selected_suffix: "agents sélectionnés",
      select_all: "Tout sélectionner",
      layer_name: "Nom du calque"
    },
    mobile: "Objets mobiles",
    statics: "Objets statiques",
    stopped: "Objets mobiles arrêtés",
    activity: "Affichage selon activité",
    follow: "Suivi d'un agent",
    follow_dialog: {
      title: "Suivi d'un agent",
      selection: "Sélection",
      agent_type: "Type d'agent",
      agent_id: "Identifiant de l'agent",
      agent: "Agent",
      no_data: "Pas de données",
      headers: {
        hour: "Heure",
        status: "Évènement"
      }
    },
    webgl: "WebGL (test)",
    dialog: {
      intro1: "Importez un ",
      intro2: "fichier de visualisation Starling.",
      intro3: "Pour une visualisation plus fluide, il est recommandé d'utiliser le navigateur Chrome.",
      choose: "Choisissez les options de visualisation",
      interval: "Intervalle de mise à jour des objets mobiles (secondes)",
      users: "Importer les users",
      static_layer: "Utiliser le calque des objets statiques"
    },
    timeline: {
      parameters: "Paramètres",
      run: "Jouer / arrêter",
      dialog: {
        title: "Paramètres",
        speed: "Vitesse de l'animation",
        attribute: "Attribut",
        decksize: "Taille Web GL"
      }
    },
    icon_rules: {
      title: "Règles sur les icônes",
      intro1: "Ajoutez une règle sur un type d'agent pour modifier les icônes en fonction d'une propriété numérique.",
      intro2:
        "La règle est appliquée si la valeur de la propriété est comprise entre le début de l'intervale (inclus) et la fin de l'intervale (exclu).",
      intro3:
        "Pour conserver l'icône indiqué dans la trace, séléctionnez 'Original'. Pour conserver la couleur par défaut de l'icône séléctionné, laissez la couleur à 'Original'.",
      type: "Type d'agent",
      property: "Propriété",
      range: "Intervalle",
      start: "Début",
      end: "Fin (exclu)",
      icon: "Icône"
    }
  },
  kpi: {
    name: "KPI",
    file: "Import KPI Starling",
    upload: {
      intro1: "Importez un fichier KPI de Starling.",
      intro2: "Analysez les KPIs de votre simulation à l'aide d'outils statistiques."
    },
    analysis: {
      button: "Analyse",
      title: "Analyse des KPIs",
      intro1: "Nombre d'entrée pour les KPIs affichés : ",
      intro2:
        "Ces tableaux affichent des mesures statistiques simples sur les KPIs fournis. Des KPIs complexes tels que les ChargeKPI ou les TransferKPI auront un rendu moins intéressant.",
      numeric_tab: "KPIs numériques",
      literal_tab: "KPIs catégoriels",
      headers: {
        KPI: "KPI",
        sum: "Somme",
        mean: "Moyenne",
        sd: "Écart-type",
        min: "Min",
        max: "Max",
        value: "Valeur",
        count: "Décompte",
        percentage: "Pourcentage"
      },
      no_data: "Pas de KPI à afficher"
    }
  },
  network: {
    name: "Réseau",
    networks: "Liste des réseaux",
    desc: "Analysez les réseaux de transport et les services de mobilité",
    tabs: {
      statistics: {
        title: "Statistiques"
      },
      variants: {
        title: "Variantes",
        headers: {
          start_stop_name: "Départ",
          end_stop_name: "Arrivée",
          num_stops: "Nombre d'arrêts",
          num_trips: "Nombre de courses"
        }
      },
      cover: {
        title: "Couverture"
      }
    },
    info_dialog: {
      title: "Analyse du réseau"
    },
    stats_labels: {
      num_routes: "# lignes",
      num_trips: "# courses",
      num_variants: "# variantes",
      num_stops: "# points d'arrêt",
      num_estimated_vehicles: "# véhicules (estimation)",
      route_name: "Ligne",
      route_type: "Type",
      is_bidirectional: "Bidirectionnel",
      start_time: "Début du service",
      end_time: "Fin du service",
      mean_trip_distance: "Distance moyenne d'une course",
      mean_trip_duration: "Temps moyen d'une course",
      min_headway: "Intervalle minimum",
      max_headway: "Intervalle maximum",
      mean_headway: "Intervalle moyen",
      service_distance: "Distance totale du service",
      service_duration: "Temps total du service",
      service_speed: "Vitesse moyenne du service",
      num_trip_starts: "# courses parties",
      num_trip_ends: "# courses arrivées",
      peak_start_time: "Début de l'heure de pointe",
      peak_end_time: "Fin de l'heure de pointe",
      peak_num_trips: "Courses en heure de pointe"
    },
    table: {
      routes: "Lignes",
      stops: "Arrêts"
    },
    add: {
      button: "Réseau TC",
      tooltip: "Ajouter un réseau de transports publics",
      database: {
        title: "Sélection d'un réseau de la base de données Tellae",
        network: "Réseau de transport",
        dates: "Dates"
      },
      project: {
        title: "Sélection d'un réseau",
        intro:
          "Les réseaux de transports publics sont au format GTFS.\
          Vous pouvez récupérer de tels fichiers sur ce site web\
          <a href='https://transport.data.gouv.fr/datasets?q=gtfs' target='_blank'>transport.gouv.fr</a>\
          ou utiliser cet <a href='static/data/example_gtfs.zip' download='example_gtfs.zip'>exemple</a>",
        placeholder: "Sélectionnez un fichier GTFS",
        upload: "Importer un fichier GTFS"
      }
    },
    gtfs: {
      error_loading: "Erreur lors du chargement des données du réseau",
      title: "Explorer un réseau de transport public",
      introduction:
        "Pour importer un fichier GTFS, vous devez:\
        <ol>\
          <li>Téléchargez un fichier GTFS sur votre ordinateur\
          (par exemple <a href='static/data/example_gtfs.zip' download='example_gtfs.zip'>celui-ci</a>,\
          ou depuis <a href='https://transport.data.gouv.fr/datasets?q=gtfs' target='_blank'>transport.gouv.fr</a>)</li>\
          <li>Importez le fichier en accédant à la configuration de votre projet.\
          Il vous faut cliquer sur le nom de votre projet (en haut à droite de la page).\
          Puis rendez vous dans la rubrique <i>RÉSEAUX</i>.</li>\
          <li>Si votre fichier n'apparaît pas dans la liste ci-dessous, veuillez recharger KITE avec F5.</li>\
        </ol>",
      file: "Fichier GTFS",
      has_all_stops: "Afficher uniquement les arrêts desseervis journée selectionnée"
    },
    draw_pt_line: {
      short_title: "Nouvelle ligne TC",
      title: "Dessiner une nouvelle ligne de transport public",
      desc: "Dessiner une ligne ou importer un fichier GeoJSON.\
      Analyser les données territoriales de cette nouvelle ligne.",
      draw: "Dessiner",
      import: "Importer un tracé",
      export_trace: "Exporter",
      tab: {
        statistics: "Statistiques",
        costs: "Coûts"
      },
      cost_model: {
        length: "Longueur (m)",
        round_trip: "Nombre d'allers-retours",
        activation: "Taux de déclenchement (%)",
        cost_km: "Coût d'exploitation €/km",
        result: "Les coûts d'exploitation sont estimés à",
        result_unit: "€ / an",
        periods: "Périodes"
      },
      data: {
        distance: "Distance autour du tracé (m)",
        desc: "Indicateurs à {buffer} m autour de la ligne",
        distance_text: "Longueur de la ligne:",
        itinerary: {
          label: "Itinéraire",
          length: "Longueur (m)"
        },
        poptot: {
          label: "Population",
          ind: "Total",
          Ind_6_10: "6 à 10 ans",
          Ind_11_17: "11 à 17 ans",
          Ind_18_24: "18 à 24 ans",
          Ind_25_39: "25 à 39 ans",
          Ind_40_54: "40 à 54 ans",
          Ind_55_64: "55 à 64 ans",
          Ind_65_79: "65 à 79 ans",
          Ind_80p: "80 ans et plus"
        },
        comp: {
          label: "Entreprises",
          "1_2": "1 à 2 salariés",
          "3_5": "3 à 5 salariés",
          "6_9": "6 à 9 salariés",
          "10_19": "10 à 19 salariés",
          "20_49": "20 à 49 salariés",
          "50_99": "50 à 99 salariés",
          "100_199": "100 à 199 salariés",
          "200_249": "200 à 249 salariés",
          "250_499": "250 à 499 salariés",
          "500_999": "500 à 999 salariés",
          "1000_1999": "1000 à 1999 salariés",
          "2000_4999": "2000 à 4999 salariés",
          "5000_9999": "5000 à 9999 salariés",
          "10000_+": "10000 salariés et plus",
          total: "Total"
        },
        sch: {
          label: "Enseignement",
          elem_schools: "Écoles (nombre)",
          mid_schools: "Collèges (nombre)",
          high_schools: "Lycées (nombre)",
          elem_schools_numbers: "Écoles (effectifs)",
          mid_schools_numbers: "Collèges (effectifs)",
          high_schools_numbers: "Lycées (effectifs)"
        },
        stat: {
          label: "Gares",
          stations: "Nombre de gares ferroviaires",
          travelers: "Fréquentation annuelle des gares"
        }
      }
    },
    dialog_variants: {
      title: "Choix des variantes",
      headers: {
        direction: "Direction",
        start_stop_name: "Départ",
        end_stop_name: "Arrivée",
        num_stops: "# Arrêts",
        num_trips: "# Courses",
        selected: "Sélectionnée"
      }
    },
    pt_isochrones: {
      title: "Évaluation d'un isochrone transport public",
      stop: "Arrêt",
      start_time: "Heure de départ",
      buffer_time: "Temps de marche maximal",
      suffix: "minute(s)"
    },
    road_isochrones: {
      button: "Isochrones",
      tooltip: "Calculer des isochrones voiture, piéton et vélo",
      title: "Calcul d'une isochrone",
      point: "Choisir le point de départ/arrivée",
      max_travel_time: "Temps de trajet max",
      layer_name: "Isochrone {max_time} min, {mode}"
    }
  },
  statistics: {
    title: "Statistiques",
    tabs: {
      network: "Réseau TC"
    },
    flows: {
      volume: "# déplacements avant filtre",
      volume_filtered: "# déplacements",
      perc_filtered: "% de déplacements conservés",
      pairs: "# paires OD",
      average_distance: "Distance moyenne (km)"
    },
    network: {
      tabs: {
        network: "Ensemble",
        route: "Ligne",
        advanced: "Avancées"
      },
      headers: {
        name: "Donnée",
        data: "Valeur"
      },
      more: "Plus"
    }
  },
  plots: {
    title: "Graphiques",
    tabs: {
      network: "Réseau"
    },
    network: {
      line: "Ligne",
      start: "Départ",
      end: "Arrivée",
      type: {
        theoretical_travel_time: "Temps de parcours",
        headway: "Intervalle"
      },
      travel_time_historic: "Réalisé LAVHV",
      series: {
        theoretical_travel_time: "Temps de parcours théorique",
        headway: "Intervalle théorique",
        real_travel_time: "Temps de parcours réalisé"
      },
      update: "Mise à jour",
      historic: "Chargement"
    }
  },
  props_mapping: {
    paint_types: {
      color: "Couleur",
      size: "Taille",
      opacity: "Transp.",
      tooltip: "Bulle"
    },
    generic: {
      coefficient: "Coefficient multiplicateur",
      default: "Autre",
      unit: "Unité : ",
      change_input_mode: "Changer de mode saisie"
    },
    layer_specifics: {
      isochrones: "Arrêt <b>{stop}</b><br />le {date} à {hour}"
    },
    constant: {
      name: "Valeur constante"
    },
    direct: {
      name: "Valeur tirée d'une propriété",
      select_label: "Propriété définissant la valeur"
    },
    category: {
      name: "Valeur par catégories"
    },
    continuous: {
      name: "Valeur par intervalles",
      less_than: "Moins de ",
      to: " à ",
      and_more: " et plus"
    },
    tooltip: {
      select_label: "Propriétés affichées dans la bulle d'information",
      select_placeholder: "Aucune",
      select_no_data: "Aucune propriété trouvée"
    },
    errors: {
      not_available: "Non disponible",
      no_editable_attribute: "Aucun attribut éditable disponible pour ce calque"
    }
  },
  ux_display: {
    legends: "Légendes",
    animationCommands: "Commandes d'animation",
    showNavigationPanel: "Afficher le panneau de navigation",
    hideNavigationPanel: "Cacher le panneau de navigation"
  },
  simulations: {
    name: "Simulation",
    simulations: "Liste des scénarios",
    desc: "Concevez et évaluez des scénarios de services de mobilité",
    pages: {
      model_selection: "Choix du service de mobilité",
      model_scenarios: "Liste des scénarios ({service_name})",
      use_case_selection: "Choix du cas d'usage ({service_name})",
      scenario_creation: "Création du scénario ({service_name})"
    },
    tooltip_add: "Nouveau scénario",
    tooltip_analysis: "Nouvelle analyse",
    analysis: "Analyse des scénarios",
    new_scenario: "Création du scénario",
    successfull_creation: "Création du scénario réussie",
    error_use_case_param: "Erreur lors du chargement des paramètres du cas d'usage",
    launch_failed: "Echec du lancement du scénario",
    data: {
      error_trace: "Erreur au chargement de la trace du scénario",
      error_graph: "Erreur au chargement du graphe du scénario",
      error_users: "Erreur au chargement des OD du scénario",
      error_download: "Erreur au chargement des données du scénario"
    },
    stepper: {
      step: {
        scenario: "Cas d'usage : {use_case}",
        environment: "Localisation",
        demand: "Demande en déplacements",
        offer: "Service de mobilité",
        validation: "Récapitulatif des paramètres",
        info: "Description"
      },
      buttons: {
        next: "Suivant",
        previous: "Précédent",
        run_simulation: "Lancer la simulation"
      },
      rules: {
        scenario_name: {
          empty: "Le nom du scénario est obligatoire",
          length: "Le nom du scénario doit contenir moins de 50 caractères",
          special_characters: "Les caractères spéciaux dans le nom du scénario sont interdits"
        },
        comment: {
          length: "Le texte ne doit pas dépasser {max_length} caractères"
        },
        penetration_rate: {
          empty: "Le taux de pénétration est obligatoire",
          range: "Le taux de pénétration doit être entre 0% et 100%"
        }
      },
      labels: {
        scenario_name: "Nom du scénario",
        comment: "Commentaire",
        options: "Options avancées",
        demand_file: "Fichier de demande",
        penetration_rate: "Taux de pénétration (%)",
        random_seed: "Graine aléatoire",
        number_vehicles: "Nombre de véhicules",
        capacity: "Nombre de places / véh.",
        max_waiting_time: "Fenêtre de prise en charge",
        undefined: "Non défini"
      },
      headers: {
        text: "Paramètre",
        value: "Valeur"
      }
    },
    speeds: {
      title: "Choix des vitesses",
      tooltip: "Définissez les vitesses de circulation des véhicules dans la simulation",
      configure: "Configurer les vitesses"
    },
    models: {
      SB_VS: {
        title: "Véhicules partagés avec station"
      },
      FF_VS: {
        title: "Véhicules partagés sans station"
      },
      TT_PT: {
        title: "Réseau de transport public"
      },
      ODT: {
        title: "Transport à la demande"
      },
      CP: {
        title: "Covoiturage"
      },
      BUSARD: {
        title: "Ligne de bus"
      }
      // SB_VS_R: {
      //   title: "Véhicules partagés avec station et repositionnement"
      // },
      // OD_P_T: {
      //   title: "Transport à la demande avec transport public à horaires"
      // }
    },
    use_cases: {
      ODT: {
        odt_railway_station: {
          description: {
            scenario:
              "Le cas d'usage porte sur un service de transport à la demande en rabattement sur une gare du\
              réseau ferroviaire. La simulation est adaptée à une gare périurbaine ou rurale.\
              Le périmètre du service est délimité par une aire circulaire autour\
              de la gare. Vous choisissez le dimensionnement de votre service.\
              Vous choisissez également le taux de pénétration du service sur la demande potentielle en rabattement.",
            environment:
              "Le périmètre du service est délimité par une aire circulaire autour de\
              la gare sélectionnée. Veuillez sélectionner la gare et paramétrer l'aire circulaire.",
            demand:
              "Le taux de pénétration vous permet d'estimer le volume de demande capté par le service\
              de transport à la demande.",
            demand_options:
              "La graine aléatoire vous permet de modifier les tirages\
              aléatoires (demande capté, heures de départs et origines / destinations). Changez la graine\
              aléatoire pour tester la robustesse du service.",
            offer:
              "Les véhicules possèdent tous le même nombre de places.\
              La qualité de service est déterminée par le temps d'attente maximum des usagers du service."
          },
          title: "Rabattement autour d'une gare",
          labels: {
            railway_station: "Gare",
            radius: "Rayon de l'aire circulaire"
          },
          rules: {},
          suffix: {
            radius: "mètres"
          },
          tooltip: {
            update_station: "Mise à jour avec l'étendue de la carte"
          }
        },
        odt_basic: {
          description: {
            scenario: "Simulez un service TàD basé sur votre estimation des requêtes voyageurs.",
            environment:
              "La zone de service est calculée automatiquement à partir des requêtes voyageurs.<br/>\
              Veuillez configurer les vitesses des véhicules TàD en fonction du type de route.",
            demand: "Choisissez un fichier de demande préalablement importé dans votre projet (onglet FLUX).",
            offer:
              "Tous les véhicules possèdent le même nombre de places et le même dépôt.\
              La qualité de service est déterminée par la fenêtre de prise en charge des usagers du service.<br/>\
              Veuillez configurer les véhicules."
          },
          title: "Service TàD générique",
          labels: {
            depot: "Localisation du dépôt"
          }
        },
        odt_reduced_mobility: {
          title: "Service porte à porte pour les PMR"
        },
        odt_rural_area: {
          title: "Nouveau service zonal en territoire rural"
        },
        odt_replace_bus: {
          title: "Remplacement d'une ligne de bus régulière"
        }
      },
      FF_VS: {
        ff_vs_new_service: {
          description: {
            scenario:
              "Le cas d'usage porte sur la création d'un nouveau service\
              en libre-service sans station. Après avoir sélectionné le\
              territoire de déploiement, vous pouvez choisir le nombre de vélos\
              et leur localisation. Vous choisissez également le taux de\
              pénétration du service sur la demande potentielle.",
            environment:
              "Le service de vélos en libre-service sans station est déployé\
              sur le périmètre de la commune sélectionnée. Seules les communes\
              des départements de la Loire-Atlantique et de l'Ille-et-Vilaine\
              sont disponibles pour la simulation.",
            demand:
              "Le taux de pénétration vous permet d'estimer le volume de\
              demande capté par le service. Le taux s'applique à la demande\
              en déplacements internes à la commune sélectionnée et de plus de 1km.",
            offer:
              "Vous pouvez modifier le nombre de vélos, ainsi que\
              leur localisation en début de journée.\
              <ul>\
              <li>Aléatoire: génération aléatoire des vélos</li>\
              <li>Grille: localisation suivant une grille</li>\
              <li>Densité: pondération par la densité de population</li>\
              </ul>"
          },
          title: "Nouveau service de vélos",
          labels: {
            commune: "Commune",
            number_bikes: "Nombre de vélos",
            location: "Localisation"
          },
          rules: {},
          no_data_text: {
            commune: "Aucune commune trouvée"
          },
          locations: {
            random: "Aléatoire",
            grid: "Grille",
            density: "Densité"
          },
          tooltip: {
            update_communes: "Mise à jour avec l'étendue de la carte"
          }
        },
        ff_vs_competition: {
          title: "Nouveau service dans une aire spécifique (campus universitaire, zone d'activité, etc)"
        },
        ff_vs_pricing: {
          title: "Optimisation de la stratégie tarifaire"
        },
        ff_vs_relocation: {
          code: "ff_vs_relocation",
          title: "Optimisation des opérations de rééquilibrage et de recharge"
        }
      },
      TT_PT: {
        tt_pt_delete_bus_routes: {
          description: {
            scenario: "Le cas d'usage porte sur l'impact de la fermeture d'une ou plusieurs lignes.",
            environment: "La simulation porte sur le périmètre du <b>réseau</b> sélectionné.",
            demand:
              "Le <b>taux de pénétration</b> vous permet d'estimer le volume de demande capté par le service de transports publics.",
            offer: "Les <b>lignes</b> sélectionnées sont supprimées du réseau de transport publics."
          },
          title: "Fermeture d'une ou plusieurs lignes",
          labels: {
            metropolis: "Réseau de transport public",
            closed_routes: "Ligne(s) coupée(s)"
          },
          rules: {},
          placeholders: {
            closed_routes: "Aucune ligne coupée"
          }
        },
        tt_pt_add_new_route: {
          title: "Mise en service d'une nouvelle ligne"
        },
        tt_pt_timetable: {
          title: "Restructuration du réseau"
        },
        tt_pt_perturbation: {
          title: "Développement de l'intermodalité"
        }
      },
      SB_VS: {
        sb_vs_service_reorganization: {
          description: {
            scenario:
              "Le cas d'usage porte sur l'évolution d'un service existant de vélos\
              en libre-service avec stations. Sans changer le nombre de stations,\
              vous pouvez adapter le nombre de vélos. Vous choisissez également la\
              localisation des vélos en début de journée et si vous souhaitez mettre\
              en place un rééquilibrage au cours de la journée ou non. Enfin, vous\
              pouvez simuler une évolution du volume de demande capté par le service.",
            environment: "La simulation porte sur le service et la date sélectionnés.",
            demand:
              "Le pourcentage de la demande s'applique à la demande actuelle.\
              Vous pouvez ainsi réduire (inférieur à 100%) ou augmenter le volume\
              de demande (supérieur à 100%).",
            offer:
              "Vous pouvez modifier le nombre de vélos (le nombre proposé est celui\
              de la journée sélectionnée), ainsi que leur localisation\
              en début de journée. Le rééquilibrage des vélos en cours de journée peut\
              être activé ou désactivé.\
              <ul>\
              <li>Historique: reprise de la localisation des vélos de la journée sélectionnée</li>\
              <li>Uniforme: chaque station possède le même taux de remplissage</li>\
              <li>Pondération par la population: le nombre de vélos dépend de la population dans les environs de chaque station</li>\
              <ul>"
          },
          title: "Évolution d'un service existant de vélos en libre service",
          labels: {
            city: "Service",
            // day_type: "Type de jours",
            date: "Date",
            demand_percentage: "Pourcentage de la demande (%)",
            number_bikes: "Nombre de vélos",
            location: "Localisation",
            relocation: "Rééquilibrage"
          },
          rules: {
            // day_type: {
            //   empty: "Un type de jours doit être sélectionné"
            // },
            location: { empty: "Une localisation doit être sélectionnée" },
            demand_percentage: {
              range: "Le pourcentage de la demande doit être entre 0% et 1000%"
            }
          },
          locations: {
            historic: "Historique",
            uniform: "Uniforme",
            weighted_by_population: "Pondération par la population"
          }
          // day_types: {
          //   LAVHV: "Jours ouvrés hors vacances",
          //   SAMHV: "Samedis hors vacances",
          //   DIMFHV: "Dimanches et jours fériés hors vacances",
          //   LAVVAC: "Jours ouvrés de vacances",
          //   SAMVAC: "Samedis de vacances",
          //   DIMFVAC: "Dimanches et jours fériés de vacances"
          // },
        },
        sb_vs_carsharing_new: {
          title: "Création d'un nouveau service d'autopartage"
        },
        sb_vs_new_service: {
          title: "Création d'un nouveau service de vélos en libre service"
        },
        sb_vs_relocation: {
          title: "Optimisation des opérations de rééquilibrage"
        }
      }
    },
    scenario_list: {
      empty: "Aucun scénario",
      error: "Erreur : impossible de lister les scénarios",
      items_per_page: "Scénarios par page",
      of: "sur",
      confirm_delete: "Voulez-vous vraiment supprimer le scénario ",
      date: "Créé le {date} à {hour}",
      actions: {
        download: "Télécharger",
        od: "Flux",
        run: "Simulation",
        graph: "Graphe",
        kpis: "Indicateurs"
      },
      local_export_title: "Export d'une simulation exécutée localement",
      status: {
        QUEUED: "En préparation",
        STARTING: "Création en cours",
        RUNNING: "Exécution en cours",
        SUCCESS: "Terminé",
        ERROR: "Erreur",
        undefined: "Statut inconnu"
      }
    },
    kpis: {
      no_kpi_available: "Aucun KPI disponible pour ce modèle",
      tab_title: "Analyse",
      title: "Analyse de scénarios",
      add_scenario: "Ajouter un scénario",
      caption: "Légende",
      headers: {
        generic: {
          scenario: ["Scénario", "Nom du scénario"],
          actions: ["Actions", "Actions disponibles"],
          number_users: ["Demande simulée", "Nombre total de voyages simulés"],
          number_vehicles: ["# Véhicules", "Nombre de véhicules dans la simulation"],
          mean_walk_time: ["Temps marche (min)", "Temps moyen de marche des voyageurs en minutes"],
          mean_vehicle_time: ["Temps en véhicule (min)", "Temps moyen en véhicule des voyageurs en minutes"],
          mean_wait_time: ["Temps d'attente (min)", "Temps moyen d'attente des voyageurs en minutes"],
          mean_total_time: ["Temps total (min)", "Temps moyen total de déplacement des voyageurs en minutes"]
        },
        ODT: {
          journeys: ["Demande satisfaite", "Nombre de voyages satisfaits par le service de mobilité"],
          success: ["% Succès", "Proportion de demande satisfaite par rapport à la demande simulée"],
          KT: ["KT", "Kilomètres totaux réalisés par les véhicules"],
          KCC: ["KCC", "Kilomètres commerciaux réalisés par les véhicules (au moins 1 passager dans le véhicule)"],
          HLP: ["% HLP", "Proportion de kilomètres haut-le-pied (sans aucun voyageur)"],
          VK: ["V/K", "Nombre moyen de voyages par kilomètre de véhicule"],
          vehicles: ["Véhicules", "Nombre de véhicules utilisés"],
          courses: [
            "Courses",
            "Nombre de courses réalisées (une course est une série de voyages délimitée par une pause du véhicule sans voyageur)"
          ],
          bulking: ["Groupage", "Taux de groupage (nombre moyen de voyages par course)"]
        },
        SB_VS: {
          number_stations: ["Stations", "Nombre de stations"],
          number_fails: ["Nombre d'échecs", "Nombre d'échecs de prise/dépose d'un véhicule"],
          empty_time_stations: ["Temps à vide", "Temps à vide des stations"],
          full_time_stations: ["Temps plein", "Temps plein des stations"],
          staff_drive_distance: ["Distance rééquilibrage", "Kilomètres parcourus par le véhicule de rééquilibrage"]
        },
        FF_VS: {
          mean_utilization_rate: [
            "Taux d'utilisation",
            "Taux d'utilisation moyen des véhicules (Nombre de requêtes / Nombre de véhicules)"
          ],
          mean_utilization_time: [
            "Temps d'utilisation",
            "Temps d'utilisation moyen des véhicules (Total des heures de déplacements de véhicule / Nombre de véhicules)"
          ],
          number_unused_vehicles: ["Véhicules non utilisés", "Nombre de véhicules non utilisés"]
        },
        TT_PT: {
          kcc: ["KCC", "Kilomètres commerciaux réalisés par les véhicules"],
          service_time: [
            "Temps de service",
            "Temps de service total des véhicules, en minutes (hors temps de battement)"
          ]
        }
      }
    },
    demo_tad: {
      button: "Demo TAD",
      title: "Nouvelle simulation TAD (Starling ",
      scenario_name: "Nom du scénario",
      scenario_name_placeholder: "Nommez votre scénario de simulation",
      environment: {
        title: "Environnement",
        city: "Ville",
        gtfs: "Tables GTFS",
        date: "Date"
      },
      service: {
        title: "Service de transport",
        area: "Zone",
        connections: "Points d'interconnexion",
        connections_hint: "Stop ids séparés par des virgules",
        tw: "Fenêtre temporelle (minutes)",
        detour: "Détour max (multiplié au temps de trajet direct)",
        vehicles: "Véhicules"
      },
      agents: {
        title: "Agents du scénario",
        users: "Voyageurs",
        no_data: "Ajoutez une population synthétique via le menu Flux"
      },
      validation_message: "Votre scénario a bien été créé et est en cours d'exécution"
    },
    buttons: {
      scenarios: "Scénarios"
    }
  },
  osm: {
    highway: {
      residential: "Résidentiel",
      secondary: "Route secondaire",
      primary: "Route majeure",
      trunk: "Voie rapide",
      tertiary: "Route tertiaire",
      living_street: "Zone piétonne",
      motorway: "Autoroute",
      other: "Autres"
    }
  },
  presets: {
    jwt_login_error:
      "Une erreur est survenue lors de la lecture de l'URL fournie. Vérifiez qu'il n'y a pas d'erreur ou contactez la personne qui vous l'a partagée.",
    type_error: "Une erreur est survenue lors du chargement : type de donnée inconnu.",
    map_view: {
      load_error:
        "Une erreur est survenue lors de la récupération de la map. Contactez la personne qui vous l'a partagée et vérifiez qu'elle n'a pas été supprimée"
    }
  },
  validation: {
    mandatory: "Cette valeur est obligatoire",
    strictly_positive: "Cette valeur doit être strictement positive",
    is_number: "Cette valeur doit être un nombre",
    is_between_0_and_1: "Cette valeur doit être comprise entre 0 et 1",
    non_empty_array: "Au moins une valeur est attendue"
  },
  errors: {
    api: {
      401: "Veuillez vous connecter",
      403: "Vous n'avez pas les droits d'accès à cette fonctionnalité",
      404: "Service inexistant",
      413: "Traitement abandonné car le fichier est trop volumineux",
      504: "Serveur non disponible",
      server_error: "Le serveur a rencontré une erreur",
      unknown_error: "Erreur inconnue"
    },
    feature_access:
      "Vous n'avez pas accès à cette fonctionnalité.\
    Contactez nous par email à {mail} pour plus d'informations\
    et bénéficier d'un accès.",
    mapbox: {
      generic: "L'utilitaire de cartographie a rencontré une erreur: {message}",
      layer: "Le calque '{layer}' a rencontré une erreur: {message}"
    }
  },
  warnings: {
    screen_size_small:
      "Votre écran est très petit. Nous vous déconseillons l'utilisations de KITE avec un si petit écran. Vous pouvez toutefois poursuivre votre navigation.",
    screen_size_medium: "Votre écran est de petite taille !",
    burger_recommandation:
      "Nous vous recommandons d'utiliser le bouton aux trois barres horizontales en haut à gauche pour cacher ou afficher le panneau de navigation."
  },
  mySettings: {
    title: "Mes préférences",
    firstName: "Prénom",
    lastName: "Nom",
    jobTitle: "Poste",
    save: "Valider"
  },
  project: {
    title: "Mes projets",
    shared_projects: "Projets partagés",
    new: "Créer un nouveau projet",
    save: "Sauvegarder",
    cancelSave: "Annuler",
    default_name: "Mon projet",
    settings: {
      title: "Paramètres",
      data: {
        title: "Données",
        types: {
          spatial_data: "Donnée spatiale",
          flows: "Flux",
          gtfs: "GTFS"
        },
        headers: {
          name: "Nom",
          data_type: "Type de donnée",
          actions: "Actions"
        },
        delete_confirmation: "Voulez vous vraiment supprimer la donnée '{name}' du projet ?"
      },
      share: {
        title: "Partager",
        email: "Email à inviter",
        invite: "Inviter"
      },
      info: {
        title: "Propriétés",
        name: "Nom du projet",
        description: "Description du projet"
      }
    },
    selector: {
      label: "Sélectionner une donnée du projet",
      import: "Importer une nouvelle donnée"
    },
    binary_already_added: "Cette donnée a déjà été ajoutée au projet sous le nom {name}",
    errors: {
      get_binary: "Error while trying to get project binary info"
    }
  },
  tools: {
    title: "Boîte à outils",
    tooltip: "Boîte à outils"
  },
  territory: {
    title: "Territoire",
    menu: {
      tooltip: "Voir les indicateurs du territoire"
    },
    dialog: {
      title: "Indicateurs du territoire",
      search: "Rechercher dans le tableau",
      agg_level: "Niveau d'aggrégation",
      table_comment: "Sources INSEE, données 2021, sauf mention contraire",
      main_epci: "EPCI principale",
      additional_epci: "Ajouter des EPCI pour comparaison",
      additional_epci_button: "Valider",
      epci_table_title: "EPCI principale et comparées",
      communes_table_title: "Communes de l'EPCI principale",
      headers: {
        code: "Code",
        city: "Commune",
        cityGroup: "EPCI",
        population: "Population",
        population_previous: "Population 2015",
        population_growth: "Croissance annuelle de la population 2021/2015 (%)",
        density_population: "Densité de population (hab/km²)",
        occupied_worker: "Actifs occupés 15-64 ans (%)",
        non_occupied_worker: "Inactifs 15-64 ans (%)",
        less15yrs: "Moins de 15 ans (%)",
        more64yrs: "Plus de 64 ans (%)",
        employment: "Emplois",
        employment_previous: "Emplois 2015",
        employment_growth: "Croissance annuelle de l emploi 2021/2015 (%)",
        employment_rate_worker: "Taux d emplois par actifs de 15-64 ans (%)",
        worker_in_zone: "Actifs occupés de 15-64 ans travaillant dans la commune (%)",
        unemployment: "Chômage 15-64 ans (%)",
        poverty: "Taux de pauvreté (%)",
        modeshare_dt_pt: "Part modale TC - domicile-travail (%)",
        modeshare_dt_bike: "Part modale Vélo - domicile-travail (%)",
        modeshare_dt_car: "Part modale Voiture - domicile-travail (%)",
        modeshare_dt_walk: "Part modale Marche - domicile-travail (%)",
        companies10p: "Etablissements de 10 salariés et + en 2024",
        companies50p: "Etablissements de 50 salariés et + en 2024",
        household: "Ménages",
        household_size: "Taille des ménages",
        household_park: "Ménages disposant d'au moins un stationnement (%)",
        household_0car: "Ménages sans voiture (%)",
        household_1car: "Ménages avec 1 voiture (%)",
        household_2pcar: "Ménages avec 2 voitures ou + (%)"
      },
      captions: {
        code: "Code INSEE",
        city: "Nom de la commune",
        cityGroup: "Nom de l'EPCI",
        population: "Population totale",
        population_previous: "Population totale en 2015",
        population_growth: "Croissance annuelle moyenne de la population totale entre 2021 et 2015 en %",
        density_population: "Densité de la population totale (hab/km²)",
        occupied_worker:
          "Nombre total d'actifs occupés de 15-64 ans, en pourcentage par rapport à la population totale",
        non_occupied_worker:
          "Nombre total d'inactifs de 15-64 ans (ou actifs non occupés), en pourcentage par rapport à la population totale",
        less15yrs: "Population de moins de 15 ans, en pourcentage par rapport à la population totale",
        more64yrs: "Population de plus de 64 ans, en pourcentage par rapport à la population totale",
        employment: "Nombre total d'emplois",
        employment_previous: "Nombre total d'emplois en 2015",
        employment_growth: "Croissance annuelle moyenne du nombre d'emploi total entre 2021 et 2015 en %",
        employment_rate_worker: "Nombre d'actifs de 15-64 ans divisés par le nombre total d'emplois en %",
        worker_in_zone:
          "Proportion d'actifs occupés de 15-64 ans dont le lieu de travail habituel est situé dans la commune de résidence",
        unemployment: "Nombre d'actifs non occupés de 15-64 ans divisé par le nombre total d'actifs de 15-64 ans",
        poverty:
          "Proportion de ménages pour lesquels le niveau de vie est inférieur au seuil de 60% du niveau de vie médian",
        modeshare_dt_pt: "Part modale Transports publics pour le motif domicile-travail en pourcentage",
        modeshare_dt_bike: "Part modale Vélo pour le motif domicile-travail en pourcentage",
        modeshare_dt_car: "Part modale Voiture pour le motif domicile-travail en pourcentage",
        modeshare_dt_walk: "Part modale Marche à pied pour le motif domicile-travail en pourcentage",
        companies10p: "Nombre d'établissements de 10 salariés et plus en 2024 (données SIRENE)",
        companies50p: "Nombre d'établissements de 50 salariés et plus en 2024 (données SIRENE)",
        household: "Nombre total de ménages",
        household_size: "Taille moyenne des ménages en nombre de personnes",
        household_park: "Proportion de ménages disposant d'au moins un stationnement pour voiture",
        household_0car: "Proportion de ménages non motorisés, c'est-à-dire sans voiture",
        household_1car: "Proportion de ménages avec 1 voiture",
        household_2pcar: "Proportion de ménages avec 2 voitures ou plus"
      }
    }
  },
  capucine: {
    analysis: {
      kpis: {
        travel_time: {
          name: "temps_parcours",
          export_filename: "{lot}_ligne_{route}_{day_type}_{direction}"
        },
        production: {
          name: "production",
          export_filename: "{lot}_ligne_{route}_{day_type}"
        },
        buffer_time: {
          name: "battement",
          export_filename: "{lot}_ligne_{route}_{day_type}_{direction}"
        },
        commercial_speed: {
          name: "vitesse_commerciale",
          export_filename: "{type}_{lot}_ligne_{route}_{day_type}_{direction}"
        },
        conformity: {
          name: "conformité",
          export_filename: "{content}_{lot}_ligne_{route}_{day_type}_{direction}"
        },
        robustness: {
          name: "robustesse",
          export_filename: "{lot}_ligne_{route}_{day_type}_{direction}"
        },
        traffic: {
          name: "trafic",
          export_filename: "{lot}_ligne_{route}_{day_type}"
        },
        kcc_loss: {
          name: "pertes_kcc",
          export_filename: "{lot}_ligne_{route}_{day_type}_{direction}"
        },
        vehicle_load: {
          name: "charge",
          export_filename: "{lot}_ligne_{route}_{day_type}_{direction}"
        },
        lot_synthesis: {
          name: "synthèse",
          export_filename: "{lot}"
        },
        route_synthesis: {
          name: "synthèse",
          export_filename: "{lot}_ligne_{route}"
        }
      }
    }
  }
};
