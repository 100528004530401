<template>
  <div>
    <v-card-title class="justify-center">
      <h3>{{ mapViewName }}</h3>
    </v-card-title>
    <v-card-subtitle>
      {{ mapViewSubtitle }}
    </v-card-subtitle>
    <v-card-text>
      <v-card class="overflow-auto pa-0" height="85vh" tile :loading="async.map_view_preset > 0">
        <layer-table :layerItems="layerItems" disableDrag :actions="['paint', 'download']" />
      </v-card>
    </v-card-text>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import LayerTable from "@/components/kite/layers/layer_table.vue";

export default Vue.component("map-view-preset", {
  components: { LayerTable },
  props: ["mapView"],
  data: function () {
    return {};
  },
  computed: {
    ...mapState(["language", "async"]),
    ...mapGetters(["appPresetLoading"]),
    ...mapGetters("layers", ["displayedLayers", "getLayerView"]),
    mapViewSubtitle() {
      if (this.mapView) {
        return (
          this.mapView._creatorUser.displayName +
          ", " +
          new Date(this.mapView._creationDate).toLocaleString(this.language, {
            dateStyle: "short",
            timeStyle: "short"
          })
        );
      } else {
        return "";
      }
    },
    mapViewName() {
      if (this.mapView) {
        return this.mapView.name;
      } else {
        return "";
      }
    },
    layerItems() {
      return this.displayedLayers.map(id => this.getLayerView(id)).reverse();
    }
  }
});
</script>
