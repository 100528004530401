<!--
  Dialog to import flows from a database
-->

<template>
  <v-card>
    <v-form ref="db_flows_form" v-model="form_is_valid" lazy-validation>
      <v-card-text class="pa-8">
        <h3 :style="{ color: $vuetify.theme.themes.light.primary }">
          {{ $t("flows.database.source.text") }}
        </h3>
        <v-row>
          <v-col class="d-flex" cols="12" sm="5">
            <v-select
              prepend-icon="storage"
              v-model="selected_name"
              :items="table_names"
              :loading="loading"
              :disabled="loading"
              :label="$t('flows.database.source.data')"
              @change="updateZoning"
              :error-messages="error_message"
              :rules="[mandatoryRule]"
            >
            </v-select>
          </v-col>
          <v-col class="d-flex" cols="12" sm="3">
            <v-select
              prepend-icon="mdi-selection-marker"
              v-model="selected_zoning"
              :items="table_zonings"
              :disabled="selected_name == null || loading"
              :loading="loading"
              :label="$t('flows.database.source.zoning')"
              :rules="[mandatoryRule]"
            >
              <template slot="item" slot-scope="data">
                {{ $t("flows.zonings." + data.item) }}
              </template>
              <template slot="selection" slot-scope="data">
                {{ $t("flows.zonings." + data.item) }}
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-container>
          {{ $t("flows.database.source.date") }} : {{ date }}<br />
          {{ $t("flows.database.source.source") }} : {{ source }}
        </v-container>
        <v-divider />
        <br />
        <v-row>
          <v-col md="6">
            <h3 :style="{ color: $vuetify.theme.themes.light.primary }">{{ $t("flows.database.spatial.text") }}</h3>
            ({{ $t("flows.database.spatial.explanation.action") }} <v-icon small>my_location</v-icon>
            {{ $t("flows.database.spatial.explanation.comment") }})
            <v-row>
              <v-col>
                <v-select
                  v-model="spatial_selection_names"
                  :items="spatial_selection_names"
                  :label="$t('map_actions.zone_selection.title')"
                  :placeholder="$t('flows.database.spatial.placeholder')"
                  :disabled="loading || selected_table == null || selected_table.format.toUpperCase() != 'FLOWMAP'"
                  readonly
                  multiple
                  :rules="[arrayNonEmpty]"
                >
                  <v-icon
                    slot="prepend"
                    color="#ff7b7d"
                    :disabled="selected_table == null || selected_table.format.toUpperCase() != 'FLOWMAP'"
                    @click="select_OD_zone"
                  >
                    my_location
                  </v-icon></v-select
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  :prepend-icon="spatial_selection_modes_icons[spatial_selection_mode]"
                  v-model="spatial_selection_mode"
                  :items="spatial_selection_modes"
                  :label="$t('flows.database.spatial.join')"
                  :disabled="spatial_selection_names.length == 0"
                >
                  <template slot="item" slot-scope="data">
                    {{ $t("flows.database.spatial.operations." + data.item) }}
                    <v-spacer></v-spacer>
                    <v-icon> {{ spatial_selection_modes_icons[data.item] }} </v-icon>
                  </template>
                  <template slot="selection" slot-scope="data">
                    {{ $t("flows.database.spatial.operations." + data.item) }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical />

          <v-col md="6">
            <h3 :style="{ color: $vuetify.theme.themes.light.primary }">{{ $t("flows.database.attributes.text") }}</h3>
            <v-row>
              <v-col>
                <v-select
                  prepend-icon="rule"
                  v-model="selected_attributes"
                  :items="table_attributes"
                  :disabled="selected_table == null"
                  :label="$t('flows.database.attributes.label')"
                  multiple
                ></v-select>
              </v-col>
            </v-row>
            <h3 :style="{ color: $vuetify.theme.themes.light.primary }">{{ $t("flows.database.count.text") }}</h3>
            <v-row>
              <v-col>
                <v-text-field
                  prepend-icon="keyboard_tab"
                  v-model="min_count"
                  :disabled="selected_table == null || selected_table.format.toUpperCase() != 'FLOWMAP'"
                  :label="$t('flows.database.count.label')"
                  type="number"
                  min="0"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-card-actions class="justify-end">
          <v-btn color="primary" text @click="closeDialog">
            {{ $t("basic_dialogs.cancel") }}
          </v-btn>
          <v-btn color="primary" :disabled="!form_is_valid" text @click="validateForm">
            {{ $t("basic_dialogs.validate") }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { KITE_CONTACT } from "@/global";
import { FlowsView } from "@/models";
import { mandatoryRule, arrayNonEmpty } from "@/validation";

const DEFAULT_MIN_COUNT = 0;

export default Vue.component("flow-from-database", {
  props: ["dialog"],

  mounted() {
    this.updateSummaryFlows().catch(err => {
      this.error_message = this.$t("errors.feature_access", { mail: KITE_CONTACT });
    });
  },

  data: function () {
    return {
      date: "",
      source: "",
      lastUpdate: "",
      selected_name: null,
      selected_zoning: null,
      selected_attributes: [],
      spatial_selection_zoning: null,
      spatial_selection_layer: null,
      spatial_selection_ids: [],
      spatial_selection_names: [],
      spatial_selection_mode: "intersect",
      spatial_selection_modes: ["intersect", "intern", "incoming_outgoing", "extern", "incoming", "outgoing"],
      spatial_selection_modes_icons: {
        intersect: "expand_circle_down",
        intern: "next_plan",
        incoming: "arrow_circle_left",
        outgoing: "arrow_circle_right",
        extern: "remove_circle",
        incoming_outgoing: "swap_horizontal_circle"
      },
      min_count: DEFAULT_MIN_COUNT,
      error_message: "",
      form_is_valid: true
    };
  },
  computed: {
    ...mapState("flows", ["summaryFlows", "zoningSummaryTable"]),
    ...mapState({
      loading: state => state.async.flows_database > 0
    }),
    table_names() {
      return [...new Set(this.summaryFlows.map(item => item.name))].sort();
    },
    table_zonings() {
      return this.summaryFlows.filter(item => item.name == this.selected_name).map(item => item.zoning);
    },
    selected_table() {
      let corresponding = this.summaryFlows.filter(item => {
        return item.name == this.selected_name && item.zoning == this.selected_zoning;
      });
      if (corresponding.length == 0) {
        return null;
      } else if (corresponding.length > 1) {
        console.log("Several tables corresponding to the selected name and zoning");
      }
      return corresponding[0];
    },
    table_attributes() {
      if (this.selected_table == null) {
        return [];
      }
      let attribute_list = this.selected_table.attributes;
      return attribute_list;
    }
  },
  watch: {
    table_attributes() {
      this.selected_attributes = [];
    },
    table_zonings(value) {
      if (value != null && value.length == 1 && this.selected_zoning == null) {
        this.selected_zoning = value[0];
      }
    },
    selected_table() {
      if (this.selected_table != null) {
        this.date = this.selected_table.date;
        this.source = this.selected_table.source;
        this.lastUpdate = this.selected_table.last_update;
      }
    }
  },
  methods: {
    ...mapActions(["startMapAction"]),
    ...mapActions("flows", ["updateSummaryFlows"]),
    ...mapActions("layers", ["setLayersVisibility"]),
    updateZoning() {
      if (this.selected_zoning == null) {
        this.selected_zoning = this.table_zonings[0];
      } else if (this.table_zonings.indexOf(this.selected_zoning) == -1) {
        this.selected_zoning = this.table_zonings[0];
      } else {
        this.selected_zoning = this.selected_zoning;
      }
    },
    closeDialog() {
      this.$store.commit("flows/UPDATE_DIALOG", { dialog: "add", value: false });
    },
    resetDialog() {
      // reset component values
      this.selected_name = null;
      this.selected_zoning = null;
      this.selected_attributes = [];
      this.min_count = DEFAULT_MIN_COUNT;
      this.date = "";
      this.lastUpdate = "";
      this.spatial_selection_ids = [];
      this.spatial_selection_names = [];
      this.$refs.db_flows_form.resetValidation();
    },
    validateForm() {
      if (this.$refs.db_flows_form.validate()) {
        this.importFlowsFromDatabase();
      }
    },
    importFlowsFromDatabase() {
      console.log(this.selected_table);
      // close the dialog
      this.closeDialog();

      let promise = null;
      if (this.selected_table.format == "flowmap") {
        // call API to get flow tables
        promise = new Promise((resolve, reject) => {
          let response = this.$whale.getFlowTables({
            id_flows: this.selected_table.id_flows,
            id_locations: this.selected_table.id_locations,
            attributes: this.selected_attributes,
            min_count: this.min_count,
            zoning_dataset: this.selected_zoning,
            zoning_selection: this.spatial_selection_zoning,
            zoning_ids: this.spatial_selection_ids,
            selection_mode: this.spatial_selection_mode
          });
          response.then(result => {
            resolve(FlowsView.newFlowsView(result, this.selected_table.format.toUpperCase(), this.selected_table.name));
            // this.resetDialog();
          });
        });
      }
      if (this.selected_table.format == "starling") {
        // call API to get starling table
        promise = new Promise((resolve, reject) => {
          let response = this.$whale.getStarlingTable({
            id_starling: this.selected_table.id_starling,
            attributes: this.selected_attributes
          });
          response.then(result => {
            resolve(FlowsView.newFlowsView(result, this.selected_table.format.toUpperCase(), this.selected_table.name));
            // this.resetDialog();
          });
        });
      }

      promise.then(flows_view => {
        flows_view.addToKite();
      });
    },

    // spatial selection

    // enable the spatial selection interface
    async select_OD_zone() {
      let success = this.startMapAction({
        action: "zone_selection",
        validationHandler: this.setSpatialSeletion,
        closeHandler: this.closeSpatialSelection
      });

      // if the draw dialog opened correctly, close
      if (success) {
        this.closeDialog();
      }
    },
    setSpatialSeletion({ spatial_selection_zoning, spatial_selection_ids, spatial_selection_names }) {
      this.spatial_selection_ids = spatial_selection_ids;
      this.spatial_selection_names = spatial_selection_names;
      this.spatial_selection_zoning = spatial_selection_zoning;
    },
    closeSpatialSelection() {
      this.setLayersVisibility({ ids: "flow_selection", isVisible: false });
      this.$store.commit("flows/UPDATE_DIALOG", { dialog: "add", value: true });
    },
    mandatoryRule,
    arrayNonEmpty
  }
});
</script>
