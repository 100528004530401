<template>
  <kite-add-dialog
    :dialog="dialog.add"
    :update-dialog="updateDialog"
    :button-text="$t('network.add.button')"
    :tabs="['database']"
    :button-options="{ loading }"
    :tooltip-text="$t('network.add.tooltip')"
  >
    <template #database>
      <network-from-database />
    </template>
  </kite-add-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";
import KiteAddDialog from "@/components/kite/base/kite_add_dialog.vue";
import NetworkFromDatabase from "./network_from_database.vue";

export default Vue.component("add-network-dialog", {
  components: {
    KiteAddDialog,
    NetworkFromDatabase
  },

  data: function () {
    return {};
  },

  computed: {
    ...mapState(["async"]),
    ...mapState("network", ["dialog"]),
    loading() {
      return this.async.gtfsDraw > 0;
    }
  },

  methods: {
    updateDialog(value) {
      this.$store.commit("network/UPDATE_DIALOG", { dialog: "add", value });
    }
  }
});
</script>
