<!--
  Component representing a data table and its pagination.
-->

<template>
  <v-card>
    <v-card-title>
      {{ $t("territory.dialog.title") }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-autocomplete
            v-model="selected_epci"
            :items="epci_list"
            :label="$t('territory.dialog.main_epci')"
            item-text="name"
            item-value="id"
            return-object
            :loading="loading.epci_data > 0 || loading_entities"
            :disabled="loading_entities"
            :readonly="loading.epci_data > 0"
            @change="selectedEpciUpdate"
          />
        </v-col>
        <v-spacer />
        <v-col cols="4">
          <v-autocomplete
            v-model="temp_additional_epci"
            :items="additional_epci_choices"
            :label="$t('territory.dialog.additional_epci')"
            item-text="name"
            item-value="id"
            :loading="loading_entities || loading.addtional_epci_data"
            :disabled="!selected_epci || loading_entities"
            multiple
            prepend-icon="add"
          >
            <template v-slot:selection="{ item, index }">
              <span v-if="temp_additional_epci.length == 1"> {{ item.name }} </span>
              <v-chip v-if="temp_additional_epci.length > 1 && index === 0">
                <span>{{ $t("basic_dialogs.selected", { nb: temp_additional_epci.length }) }}</span>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mt-4"
                color="primary"
                fab
                x-small
                @click="additionalEpciUpdate"
                :disabled="!selected_epci || loading.addtional_epci_data"
              >
                <v-icon v-on="on"> check </v-icon></v-btn
              >
            </template>
            {{ $t("territory.dialog.additional_epci_button") }}
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      {{ $t("territory.dialog.table_comment") }}
      <kite-table
        :card-title="$t('territory.dialog.epci_table_title')"
        :headers="epci_headers"
        :items="epci_data"
        :loading="loading.epci_data > 0 || loading.addtional_epci_data"
        header-path-prefix="territory.dialog.headers"
        caption-path-prefix="territory.dialog.captions"
        :table-props="tableProps"
      >
        <template #body>
          <tbody>
            <tr v-for="a in epci_data.length" :key="`row-${a}`">
              <td v-for="b in epci_headers.length" :key="`row-${a}-cell-${b}`">
                <div :style="'text-align: ' + epci_headers[b - 1]['align']">
                  {{ epci_data[a - 1][epci_headers[b - 1]["value"]] }}
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </kite-table>
      <v-row class="mt-8" />
      <kite-table
        :card-title="$t('territory.dialog.communes_table_title')"
        :headers="commune_headers"
        :items="communes_data"
        :loading="loading.epci_data > 0"
        header-path-prefix="territory.dialog.headers"
        :table-props="tableProps"
      >
        <template #body>
          <tbody>
            <tr v-for="a in communes_data.length" :key="`row-${a}`">
              <td v-for="b in commune_headers.length" :key="`row-${a}-cell-${b}`">
                <div :style="'text-align: ' + commune_headers[b - 1]['align']">
                  {{ communes_data[a - 1][commune_headers[b - 1]["value"]] }}
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </kite-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";
import { TABHEADERS } from "@/global";

export default Vue.component("territory-kpi", {
  components: {},

  props: {},

  data: function () {
    return {
      selected_epci: null,
      temp_additional_epci: [],
      additional_epci: [],
      epci_list: [],
      selected_epci_data: [],
      urban_type_mean_data: [],
      stored_urban_type_mean_data: {
        "1": [],
        "2": [],
        "3": []
      },
      additional_epci_data: [],
      communes_data: [],
      tableProps: {
        fixedHeader: true,
        disablePagination: true,
        hideDefaultFooter: true
      },
      loading: {
        epci_data: 0,
        addtional_epci_data: false
      }
    };
  },

  mounted() {
    this.$whale.adminEntities("epci", ["typo_densite"]).then(result => {
      this.epci_list = result.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    });
  },

  computed: {
    ...mapState(["async"]),
    epci_headers() {
      return this.getHeaders("epci");
    },
    commune_headers() {
      return [
        ...this.getHeaders("com"),
        {
          text: "worker_in_zone",
          align: "end",
          value: "PercP21_ACTOCC_ZONE",
          width: "100px",
          sortable: false
        }
      ];
    },
    loading_entities() {
      return this.async.admin_entities > 0;
    },
    additional_epci_choices() {
      return this.epci_list.filter(epci => {
        return epci.id != this.selected_epci?.id;
      });
    },
    epci_data() {
      return [...this.selected_epci_data, this.urban_type_mean_data, ...this.additional_epci_data];
    }
  },
  methods: {
    selectedEpciUpdate() {
      // reset additional EPCI selection
      this.temp_additional_epci = [];
      this.additionalEpciUpdate();

      // get EPCI stats + stats of the communes composing the EPCI
      this.loading.epci_data += 1;
      this.$whale
        .epciStats([this.selected_epci.id], true)
        .then(res => {
          console.log(res);
          this.selected_epci_data = res?.epci_data || [];
          this.communes_data = res?.commune_data || [];
        })
        .finally(() => {
          this.loading.epci_data -= 1;
        });

      // get mean stats for EPCI urban type
      let urban_type = this.selected_epci.typo_densite.substring(0, 1);
      if (this.stored_urban_type_mean_data[urban_type].length == 0) {
        this.loading.epci_data += 1;
        this.$whale
          .urbanTypeStats(urban_type)
          .then(res => {
            this.stored_urban_type_mean_data[urban_type] = res;
            this.urban_type_mean_data = res;
          })
          .finally(() => {
            this.loading.epci_data -= 1;
          });
      } else {
        this.urban_type_mean_data = this.stored_urban_type_mean_data[urban_type];
      }
    },
    additionalEpciUpdate() {
      let missing_epcis = this.temp_additional_epci.filter(id => {
        return !this.additional_epci.includes(id);
      });

      // confirm additional EPCI selection
      this.additional_epci = [...this.temp_additional_epci];

      if (missing_epcis.length > 0) {
        console.log(missing_epcis);
        this.loading.addtional_epci_data = true;
        this.$whale
          .epciStats(this.additional_epci, false)
          .then(res => {
            this.additional_epci_data = res?.epci_data || [];
          })
          .finally(() => {
            this.loading.addtional_epci_data = false;
          });
      } else {
        this.additional_epci_data = this.additional_epci_data.filter(item => {
          return this.additional_epci.includes(item.id);
        });
      }
    },
    getHeaders(level) {
      let name_text;
      if (level == "epci") {
        name_text = "cityGroup";
      } else if (level == "com") {
        name_text = "city";
      } else {
        throw new Error("Unknown admin level");
      }

      return [
        { text: name_text, align: "start", value: "name", width: "200px", sortable: false },
        {
          text: "code",
          align: "start",
          value: "id",
          width: "100px",
          sortable: false
        },
        ...TABHEADERS.territory_kpis
      ];
    }
  }
});
</script>

<style scoped>
table > tbody > tr > td:nth-child(1),
table > thead > tr > th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 9998;
  background: white;
}
table > thead > tr > th:nth-child(1) {
  z-index: 9999;
}
</style>
