<!--
Action icon with tooltip
-->

<template>
  <v-select
    v-model="selected"
    :label="$t('flows.database.source.zoning')"
    item-value="zoning"
    :item-text="item => $t('flows.zonings.' + item.zoning)"
    v-bind="$attrs"
    :items="definitiveItems"
  />
</template>

<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.component("zoning-selector", {
  components: {},
  props: ["value", "items"],

  data() {
    return {};
  },
  computed: {
    ...mapState("flows", ["zoningSummaryTable"]),
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    definitiveItems() {
      let definitive_items;
      if (this.items) {
        if (typeof this.items[0] == "object") {
          definitive_items = this.items;
        } else {
          definitive_items = this.items.map(zoning => {
            return this.zoningSummaryTable.filter(item => item.zoning == zoning)[0];
          });
        }
      } else {
        definitive_items = this.zoningSummaryTable;
      }
      return definitive_items;
    }
  },
  methods: {}
});
</script>
